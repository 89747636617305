import { Image } from '@finn/ui-components';
import { getCloudinaryImgUrl } from '@finn/ui-utils';
import { makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(() => ({
  image: {
    width: '100%',
    objectFit: 'cover',
  },
}));

type Props = {
  src: string;
  altText?: string;
};

const PromoImage: React.FunctionComponent<Props> = ({ src, altText = '' }) => {
  const classes = useStyles();

  return (
    <Image
      variant="responsive"
      className={classes.image}
      lgImage={getCloudinaryImgUrl(src, {
        w: 625,
        dpr: 2,
      })}
      mdImage={getCloudinaryImgUrl(src, {
        w: 372,
        dpr: 2,
      })}
      smImage={getCloudinaryImgUrl(src, {
        w: 375,
        dpr: 2,
      })}
      alt={altText}
    />
  );
};

export default PromoImage;
