import { ModalKey, useOpenModal } from '@finn/ua-modals';
import { CTA, Image } from '@finn/ui-components';
import { interactionTrackingEvent, TrackingEventName } from '@finn/ui-modules';
import { useIntersectionObserver } from '@finn/ui-utils';
import { Divider } from '@material-ui/core';
import React, { useCallback, useEffect, useRef } from 'react';

import { PromoCosmicMetadata } from '~/types/UIModules';
import { parseToHtml } from '~/utils/html';

type Props = {
  data: PromoCosmicMetadata;
  slug?: string;
};

const PromoContent: React.FunctionComponent<Props> = ({ data, slug = '' }) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const isInView = useIntersectionObserver(containerRef, 0.75, '0px', true);
  const isNEON = slug === 'promo-neon' || slug === 'neon-promo';
  const isJobAuto = slug === 'promo-b2b-job-auto-3-version-2';
  const openModal = useOpenModal();

  useEffect(() => {
    if (isNEON && isInView) {
      interactionTrackingEvent(TrackingEventName.NEON_CAMPAIGN_VIEWED, {});
    }
  }, [isInView]);

  const handleClick = useCallback(
    (CTAMetadata) => {
      if (isNEON) {
        interactionTrackingEvent(TrackingEventName.NEON_CAMPAIGN_CLICKED, {
          link: CTAMetadata.href,
        });
      }
      if (isJobAuto) {
        openModal(ModalKey.JOBAUTO_WHITEPAPER_MODAL);
      }
    },
    [isNEON]
  );

  return (
    <div ref={containerRef}>
      <h2 className="mobile-t2-semibold md:web-t2-semibold">{data.title}</h2>
      <div className="body-16-light py-8">{parseToHtml(data.description)}</div>
      {data.cta && (
        <CTA
          data={data.cta.metadata}
          textColor={data?.dark_text ? 'black' : 'white'}
          parentBG={data?.dark_text ? 'light' : 'dark'}
          onCTAClick={() => handleClick(data.cta.metadata)}
        />
      )}
      {data?.footer_icon?.url && (
        <>
          <Divider className="!h-16 !bg-transparent" />
          <div className="mt-auto">
            <Image src={data.footer_icon.url} />
          </div>
        </>
      )}
    </div>
  );
};

export default PromoContent;
