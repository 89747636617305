import { useIsMobile } from '@finn/ui-utils';
import { makeStyles } from '@material-ui/core';
import cn from 'classnames';
import React, { useRef } from 'react';
import { useInViewport } from 'react-in-viewport';

import { PromoCosmicMetadata } from '~/types/UIModules';

import PromoContent from './PromoContent';
import PromoImage from './PromoImage';

type StyleProps = {
  isReversed: boolean;
  bgColor: string;
  darkText?: boolean;
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    backgroundColor: (props: StyleProps) => props.bgColor,
    [theme.breakpoints.up('sm')]: {
      minHeight: 448,
    },
  },
  imageWrapper: {
    height: 440,
    width: '100%',
    objectFit: 'cover',
    overflow: 'hidden',
    '& picture': {
      height: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '47%',
      height: 'auto',
    },
    order: 2,
  },
  imageWrapperReversed: {
    order: 1,
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    order: 1,
    minHeight: 400,
    padding: theme.spacing(7.5, 2),
    transition: '0.8s',
    opacity: 0,
    transform: 'translate(0, 50px)',

    '&.visible': {
      opacity: 1,
      transform: 'none',
    },
    [theme.breakpoints.up('sm')]: {
      width: '53%',
      padding: theme.spacing(7),
    },
  },
  contentWrapperReversed: {
    order: 2,
  },
}));

type Props = {
  data: PromoCosmicMetadata;
  slug?: string;
};

const PromoMobile: React.FunctionComponent<Props> = ({ data, slug = '' }) => {
  const classes = useStyles({
    isReversed: data.reverse,
    bgColor: data.color_hex,
    darkText: data.dark_text,
  });
  const isMobile = useIsMobile();

  const containerRef = useRef();
  const { enterCount } = useInViewport(
    containerRef,
    {
      threshold: 0.2,
    },
    { disconnectOnLeave: true },
    {}
  );

  const visible = enterCount >= 1;

  return (
    <div className={classes.wrapper}>
      <div
        ref={containerRef}
        className={cn(classes.contentWrapper, {
          'text-black': data.dark_text,
          'text-white': !data.dark_text,
          [classes.contentWrapperReversed]:
            data.reverse || (data.reverse_mobile && isMobile),
          visible,
        })}
      >
        <PromoContent data={data} slug={slug} />
      </div>
      <div
        className={cn(classes.imageWrapper, 'bg-black', {
          [classes.imageWrapperReversed]:
            data.reverse || (data.reverse_mobile && isMobile),
        })}
      >
        <PromoImage
          src={data.image?.url}
          altText={data?.image_alt_text || data.title}
        />
      </div>
    </div>
  );
};

export default PromoMobile;
