import { Box, Hidden } from '@material-ui/core';
import React from 'react';

import { PromoCosmicMetadata } from '~/types/UIModules';

import PromoDesktop from './PromoDesktop';
import PromoMobile from './PromoMobile';

type Props = {
  data: PromoCosmicMetadata;
  id?: string;
  slug?: string;
};

const Promo: React.FunctionComponent<Props> = ({
  data,
  id = '',
  slug = '',
}) => {
  return (
    <Box id={id || data.id} data-testid="promo">
      <Hidden smDown implementation="css">
        <PromoDesktop data={data} slug={slug || id} />
      </Hidden>
      <Hidden mdUp implementation="css">
        <PromoMobile data={data} slug={slug || id} />
      </Hidden>
    </Box>
  );
};

export default Promo;
